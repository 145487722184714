<template>
  <div>
    <div class="mt-2 mb-2 title-float accent--text">
          {{$tc('label.kpis_acoes_relacionadas', 1)}}
    </div>
  <v-card>
    <v-container grid-list-xl fluid class="mt-4" >
      <v-data-table
        class="mt-6"
        :headers="cabecalho"
        :items="kpis.acoes"
        :footer-props="{
          itemsPerPageOptions: [5,10,20],
        }"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        dense>

        <template v-slot:item.acoes="{ item }">
           <v-container class="d-flex flex-row" >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon  @click="detalheItem(item)" v-on="on">
              <v-icon>visibility</v-icon>
               </v-btn>
              </template>
              <span>{{ $t('label.mais_detalhes') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click="excluirAcao(item)" v-show="podeExcluir">
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.excluir') }}</span>
            </v-tooltip>
          </v-container>
        </template>

        <template v-slot:item.vlrAcao="{ item }">
         {{getMoney(item.vlrAcao)}}
        </template>

        <template v-slot:item.status="{ item }">
          <kpis-status-acoes :status="item.status" :tooltip="item.status"></kpis-status-acoes>
        </template>

      </v-data-table>

    <kpis-form-acoes-relacionadas-modal v-if="podeAdicionarAcao"
    :kpis="kpis" >
    </kpis-form-acoes-relacionadas-modal>

   </v-container>
  </v-card>
  <confirm
      ref="confirmDialog"
      :message="getMessageExclusao"
      @agree="removeAcao">
    </confirm>
  </div>
</template>

<script>

import KpisFormAcoesRelacionadasModal from './KpisFormAcoesRelacionadasModal';
import KpisStatusAcoes from './KpisStatusAcoes';
import { getMoney } from '../../produto/common/functions/helpers';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'KpisFormItem',
  components: {
    KpisFormAcoesRelacionadasModal,
    KpisStatusAcoes,
    Confirm,
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    kpis: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      acaoSelecionada: null,
      cabecalho: [
        {
          text: this.$tc('label.acoes', 2), value: 'acoes', sortable: false, width: '10',
        },
        {
          text: this.$tc('label.tipo_acao', 1), value: 'nomTipo', sortable: true,
        },
        {
          text: this.$tc('label.cod_planejamento_acao_akzo', 1), value: 'id', sortable: true, align: 'center',
        },
        {
          text: this.$tc('label.cliente_do_focao_acao', 1), value: 'nomCliente', sortable: true,
        },
        {
          text: this.$tc('label.projeto', 1), value: 'nomProjeto', sortable: true,
        },
        {
          text: this.$tc('label.tipo_de_verba', 1), value: 'nomVerba', sortable: true,
        },
        {
          text: this.$tc('label.data_inicio', 1), value: 'dtaInicio', sortable: true,
        },
        {
          text: this.$tc('label.data_fim', 1), value: 'dtaFim', sortable: true,
        },
        {
          text: this.$tc('label.valor', 1), value: 'vlrAcao', sortable: false,
        },
        {
          text: this.$tc('label.status', 1), value: 'status', sortable: false,
        },
      ],
    };
  },
  computed: {
    podeAdicionarAcao() {
      return this.kpis && (this.kpis.instituicao || this.kpis.projeto) && !this.somenteLeitura;
    },
    getMessageExclusao() {
      const msg = this.$t('message.kpis_excluir_acao');
      return this.acaoSelecionada && this.acaoSelecionada.id ? msg.replace('[cod_planejamento]', this.acaoSelecionada.id) : msg;
    },
    podeExcluir() {
      return !this.somenteLeitura;
    },
  },
  methods: {
    getMoney,
    detalheItem(item) {
      const idAcao = item.id;
      this.route = this.$router.resolve({ name: 'detalharAcao', params: { idAcao, from: 'planejamento-acao' } });
      window.open(this.route.href, '_blank');
    },
    excluirAcao(item) {
      this.acaoSelecionada = item;
      this.$refs.confirmDialog.open();
    },
    removeAcao() {
      const index = this.kpis.acoes.map((s) => s.id).indexOf(this.acaoSelecionada.id);
      if (index >= 0) this.kpis.acoes.splice(index, 1);
      this.acaoSelecionada = null;
    },
    podeDeletarAcao() {
      return this.kpis.status && this.kpis.status === 'EM_EXECUCAO';
    },
  },

};
</script>
