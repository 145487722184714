var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-2 mb-2 title-float accent--text"},[_vm._v(" "+_vm._s(_vm.$tc('label.kpis_acoes_relacionadas', 1))+" ")]),_c('v-card',[_c('v-container',{staticClass:"mt-4",attrs:{"grid-list-xl":"","fluid":""}},[_c('v-data-table',{staticClass:"mt-6",attrs:{"headers":_vm.cabecalho,"items":_vm.kpis.acoes,"footer-props":{
        itemsPerPageOptions: [5,10,20],
      },"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"dense":""},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
      var item = ref.item;
return [_c('v-container',{staticClass:"d-flex flex-row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.detalheItem(item)}}},on),[_c('v-icon',[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.mais_detalhes')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.podeExcluir),expression:"podeExcluir"}],staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.excluirAcao(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.excluir')))])])],1)]}},{key:"item.vlrAcao",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.vlrAcao))+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('kpis-status-acoes',{attrs:{"status":item.status,"tooltip":item.status}})]}}])}),(_vm.podeAdicionarAcao)?_c('kpis-form-acoes-relacionadas-modal',{attrs:{"kpis":_vm.kpis}}):_vm._e()],1)],1),_c('confirm',{ref:"confirmDialog",attrs:{"message":_vm.getMessageExclusao},on:{"agree":_vm.removeAcao}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }