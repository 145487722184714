<template>
  <div>
    <div class="mt-2 mb-2 title-float accent--text">
       {{$tc('label.kpis_sust', 1)}}
    </div>
  <v-card>
   <v-container grid-list-xl fluid class="mt-4" >
    <v-row>
      <v-col cols="3">
        <v-text-field
          :label="$tc('label.kpis_imoveis', 1)"
          prepend-icon="filter_1"
          :disabled="somenteLeitura"
          type="Number"
          :rules="[rules.valorPositivoInteiro]"
          :min="0"
          v-model="kpis.imoveis">
        </v-text-field>
      </v-col>
      <v-col cols="3" class="mt-3">
        <input-decimal
            id="metrogem_total"
            v-model="kpis.metragemTotal"
            :disabled="somenteLeitura"
            prepend-icon="filter_1"
            dense
            :rules="[rules.valorPositivo]"
            :label="$tc('label.kpis_metragem_total', 1)"/>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :label="$tc('label.kpis_metragem_pintura_interna', 1)"
          prepend-icon="filter_1"
          :disabled="somenteLeitura"
          :maxlength="20"
          v-model="kpis.metragemPinturaInterna">
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :label="$tc('label.kpis_metragem_pintura_externa', 1)"
          prepend-icon="filter_1"
          :disabled="somenteLeitura"
          :maxlength="20"
          v-model="kpis.metragemPinturaExterna">
        </v-text-field>
        </v-col>
        <v-col cols="3">
        <v-text-field
          :label="$tc('label.kpis_pessoas_impactadas', 1)"
          prepend-icon="filter_1"
          type="Number"
          :disabled="somenteLeitura"
          :min="0"
          :rules="[rules.valorPositivoInteiro]"
          v-model="kpis.pessoasImpactadas">
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :label="$tc('label.kpis_pintores_treinados', 1)"
          prepend-icon="filter_1"
          :disabled="somenteLeitura"
          type="Number"
          :min="0"
          :rules="[rules.valorPositivoInteiro]"
          v-model="kpis.pintoresTreinados">
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :label="$tc('label.kpis_voluntario_interno', 1)"
          prepend-icon="filter_1"
          type="Number"
          :disabled="somenteLeitura"
          :min="0"
          :rules="[rules.valorPositivoInteiro]"
          v-model="kpis.voluntariosInternos">
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :label="$tc('label.kpis_voluntario_externo', 1)"
          prepend-icon="filter_1"
          type="number"
          :disabled="somenteLeitura"
          :min="0"
          :rules="[rules.valorPositivoInteiro]"
          v-model="kpis.voluntariosExternos">
        </v-text-field>
      </v-col>
      <v-col cols="3" class="mt-3">
        <input-decimal
            id="volume_tinta"
            v-model="kpis.volumeTinta"
            prepend-icon="filter_1"
            :disabled="somenteLeitura"
            dense
            :rules="[rules.valorPositivo]"
            :label="$tc('label.kpis_volume_tinta', 1)"/>
      </v-col>
      <v-col cols="6" sm="6" md="6" slot="antes">
        <v-textarea
          id="campo_observacao"
          name="campo_nome"
          v-model="kpis.observacao"
          :label="`${$tc('label.observacao', 1)}`"
          :disabled="somenteLeitura"
          :counter="1000"
          maxlength="1000"
          auto-grow
          rows="1"
          >
        </v-textarea>
      </v-col>
    </v-row>
   </v-container>
  </v-card>
  </div>
</template>

<script>

import InputNumber from '@/produto/shared-components/inputs/InputNumber';
import InputDecimal from '@/produto/shared-components/inputs/InputDecimal';
import { getMoney } from '../../produto/common/functions/helpers';

export default {
  name: 'KpisFormItem',
  components: {
    InputNumber,
    InputDecimal,
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    kpis: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        valorPositivo: (v) => (v >= 0) || this.$t('message.valor_invalido'),
        valorPositivoInteiro: (v) => {
          if (!v) return true;
          const msg = this.$t('message.numero_invalido');
          const item = v.toString().includes('.') || v.toString().includes(',');
          const isNumber = (!item && !Number.isNaN(v.toString()) && (v % 1) === 0);
          return (isNumber && v >= 0) || msg;
        },

      },
    };
  },
  computed: {
    isStatusConcluido() {
      return this.kpis && this.kpis.status === 'CONCLUIDO';
    },
    isStatusEmExecucaoConcluido() {
      return this.kpis && (this.kpis.status === 'CONCLUIDO' || this.kpis.status === 'EM_EXECUCAO');
    },
  },
  methods: {
    getMoney,
    validateItens() {
      if (this.kpis && this.isStatusEmExecucaoConcluido && !this.itemValido(this.kpis.imoveis) && !this.itemValido(this.kpis.metragemTotal)
        && !this.itemValido(this.kpis.metragemPinturaInterna)
        && !this.itemValido(this.kpis.metragemPinturaExterna) && !this.itemValido(this.kpis.pessoasImpactadas)
        && !this.itemValido(this.kpis.pintoresTreinados)
        && !this.itemValido(this.kpis.voluntariosInternos) && !this.itemValido(this.kpis.voluntariosExternos)
        && !this.itemValido(this.kpis.volumeTinta)) {
        this.$toast(this.$t('message.kpis_obrigatorio_preencher_kpis'));
        return false;
      }
      return true;
    },
    itemValido(item) {
      return item && item !== '' && item > 0;
    },

  },

};
</script>
