<template lang="html">
  <v-form ref="form" lazy-validation autocomplete="off">
    <v-card class="elevation-1">
      <v-card-title>
        {{ getTitulo }}
      </v-card-title>
      <v-container grid-list-xl fluid >
        <metadados-container-layout
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="kpis"
          :formulario-filtros="false"
          :somenteLeitura="somenteLeitura"
          :novoCadastro="true"
          @MetadadosContainerLayout_abrirCadastro="abrirCadastro"
          ref="container">
          <v-col cols="12" sm="12" md="12" slot="antes" class="pt-0 pb-0">
            <v-radio-group v-model="kpis.tipo" row @change="tipoSelecionado">
              <v-radio :label="$t('label.instituicao')" :value="'INSTITUICAO'" :disabled="somenteLeitura"></v-radio>
              <v-radio :label="$t('label.projeto')" :value="'PROJETO'" :disabled="somenteLeitura"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="12" md="6" slot="antes" class="pt-0 pb-0" v-if="!projetoSelecionado">
            <v-autocomplete
              id="kpis_cliente"
              class="custom-autocomplete"
              v-model="kpis.instituicao"
              :items="clientes"
              :label="`${$tc('label.instituicao', 1)} *`"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="codNome"
              item-value="id"
              :rules="[rules.required]"
              required
              :clearable="true"
              :search-input.sync="buscaListaClientes"
              @click.native="buscaClientes"
              return-object
              @input="valorAlterado"
              :disabled="somenteLeitura || !podeSelecionar"
              >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="6" slot="antes" class="pt-0 pb-0" v-if="projetoSelecionado">
            <v-autocomplete
              :disabled="somenteLeitura || !podeSelecionar"
              id="kpis_projeto"
              class="kpis_projeto"
              v-model="kpis.projeto"
              :items="projetos"
              :label="`${$tc('label.projeto', 1)} *`"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomProjeto"
              item-value="id"
              :rules="[rules.required]"
              required
              :clearable="true"
              :search-input.sync="buscaListaProjetos"
              @click.native="buscarProjetos"
              return-object
              @input="valorAlterado"
              >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="6" slot="antes" class="pt-0 pb-0">
            <v-textarea
              id="campo_nome"
              name="campo_nome"
              v-model="kpis.nomDoacao"
              :label="`${$tc('label.kpis_nome_doacao', 1)} *`"
              :rules="[rules.required]"
              required
              :disabled="somenteLeitura"
              :counter="200"
              maxlength="200"
              auto-grow
              rows="1"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="4" md="3" slot="antes" class="pt-0 pb-0">
            <v-select
              id="status_kpis"
              name="status_kpis"
              :items="status"
              :label="`${$tc('label.status', 1)} *`"
              v-model="kpis.status"
              item-text="texto"
              item-value="valor"
              chips
              :disabled="somenteLeitura"
              deletable-chip
              clearable
              :rules="[rules.required]"
              required
              >
            </v-select>
          </v-col>
        </metadados-container-layout>
      </v-container>
    </v-card>

    <kpis-form-acoes
      :kpis="kpis"
      :somenteLeitura="somenteLeitura">
    </kpis-form-acoes>

    <kpis-form-item
      ref="formItem"
      :kpis="kpis"
      :somenteLeitura="somenteLeitura">
    </kpis-form-item>

    <v-card>
      <v-container grid-list-xl fluid class="mt-4" >
        <kpis-form-anexo-arquivo :kpis="kpis" :somenteLeitura="somenteLeitura">
        </kpis-form-anexo-arquivo>
      </v-container>
    </v-card>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn id="btn_cancelar_novo_kpis" @click.native="openCancelarDialog">{{ somenteLeitura ?  $t('label.voltar') :  $t('label.cancelar') }}</v-btn>
      <v-btn id="btn_salvar_novo_kpis" v-if="!somenteLeitura && !isConcluidoCancelado" @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
    </v-card-actions>

    <confirm
      ref="confirmDialog"
      :message="getMessageConfirmacao"
      @agree="salvar">
    </confirm>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="somenteLeitura ? $t('message.deseja_voltar') : $t('message.tem_certeza_cancelar')"
      @agree="cancelar">
    </confirm>

  </v-form>
</template>
<script>

import MetadadosContainerLayout from '../../produto/shared-components/metadados/MetadadosContainerLayout';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';
import { buscarClientes, buscarProjetosSelecao } from '../../common/resources/akzo-acao-projeto';
import { getMoney } from '../../produto/common/functions/helpers';
import { formatDatePattern } from '../../produto/common/functions/date-utils';
import { skipLoading } from '../../produto/common/functions/loading';
import KpisFormItem from './KpisFormItem';
import KpisFormAcoes from './KpisFormAcoes';
import KpisFormAnexoArquivo from './KpisFormAnexoArquivo';

export default {
  name: 'KpisForm',
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MetadadosContainerLayout,
    Confirm,
    KpisFormItem,
    KpisFormAcoes,
    KpisFormAnexoArquivo,
  },
  data() {
    return {

      resourceProjeto: this.$api.akzoAcaoProjeto(this.$resource),
      resourceKpis: this.$api.akzoKpis(this.$resource),

      kpis: {
        id: null,
        tipo: null,
        status: 'EM_EXECUCAO',
        projeto: null,
        instituicao: null,
        imoveis: null,
        metragemTotal: 0,
        metragemPinturaInterna: null,
        metragemPinturaExterna: null,
        pessoasImpactadas: null,
        pintoresTreinados: null,
        voluntariosInternos: null,
        voluntariosExternos: null,
        volumeTinta: 0,
        observacao: null,
        acoes: [],
      },
      buscaListaClientes: null,
      buscaListaProjetos: null,
      clientes: [],
      projetos: [],
      statusEdicao: null,
      status: [
        {
          texto: this.$tc('label.em_execucao', 1),
          valor: 'EM_EXECUCAO',
        },
        {
          texto: this.$tc('label.cancelado', 1),
          valor: 'CANCELADO',
        },
        {
          texto: this.$tc('label.concluido', 1),
          valor: 'CONCLUIDO',
        },
      ],

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    instituicaoSelecionado() {
      return this.kpis.tipo === 'INSTITUICAO';
    },
    projetoSelecionado() {
      return this.kpis.tipo === 'PROJETO';
    },
    getTitulo() {
      const codKpis = this.kpis && this.kpis.id ? ` - ${this.kpis.id}` : '';
      return `${this.$tc('label.cadastro', 1)} :: ${this.$tc('title.kpis_sustentabilidade', 1)} ${codKpis}`;
    },
    getMessageConfirmacao() {
      if (this.kpis && this.kpis.id && this.kpis.status && this.kpis.status === 'CANCELADO') {
        return this.$tc('message.kpis_confirmacao_cancelamento');
      }
      return this.$t('message.deseja_salvar', { text: this.$tc('label.kpis_sust', 1).toLowerCase() });
    },
    podeSelecionar() {
      return !!this.kpis.tipo;
    },

    isConcluidoCancelado() {
      return this.statusEdicao === 'CANCELADO' || this.statusEdicao === 'CONCLUIDO';
    },
    isStatusConcluido() {
      return this.kpis && this.kpis.id && this.kpis.status === 'CONCLUIDO';
    },
    isStatusCancelado() {
      return this.statusEdicao === 'CANCELADO';
    },

  },
  watch: {
    buscaListaClientes(val) {
      const { instituicao } = this.kpis;
      if (instituicao && instituicao.codNome === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaClientes(val);
      }, 500);
    },
    buscaListaProjetos(val) {
      const { projeto } = this.kpis;
      if (projeto && projeto.nomProjeto === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscarProjetos(val);
      }, 500);
    },
  },
  methods: {
    getMoney,
    formatDatePattern,
    valorAlterado() {
      this.kpis.acoes = [];
    },
    tipoSelecionado() {
      this.kpis.acoes = [];
      if (this.kpis.tipo === 'INSTITUICAO') {
        this.kpis.projeto = null;
        this.kpis.idAcaoProjeto = null;
      }

      if (this.kpis.tipo === 'PROJETO') {
        this.kpis.instituicao = null;
        this.kpis.idCliente = null;
      }
    },
    buscaClientes(autocomplete) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      const parametros = {
        autocomplete,
      };

      buscarClientes(parametros, this.$resource)
        .then((res) => {
          this.clientes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarProjetos(autocomplete) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      const parametros = {
        autocomplete,
      };
      buscarProjetosSelecao(parametros, this.$resource)
        .then((res) => {
          this.projetos = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    openCancelarDialog() {
      if (this.somenteLeitura) {
        this.cancelar();
        return;
      }
      this.$refs.cancelarDialog.open();
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.$refs.confirmDialog.open();
    },
    salvar() {
      if (!this.$refs.formItem.validateItens()) {
        return;
      }

      if (this.kpis.acoes.length < 1 && !this.isStatusCancelado) {
        this.$toast(this.$t('message.kpis_obrigatorio_relacionar_acao'));
        return;
      }

      if (this.kpis.id) {
        this.atualizar(this.kpis);
      } else {
        this.gravar(this.kpis);
      }
    },
    gravar(obj) {
      obj.idAcaoProjeto = obj.projeto && obj.projeto.id ? obj.projeto.id : null;
      obj.idCliente = obj.instituicao && obj.instituicao.id ? obj.instituicao.id : null;
      obj.idsAcao = obj.acoes.map((v) => v.id);
      this.resourceKpis.gravar(obj)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.kpis = {};
          this.$router.push({ name: this.$route.params.from || 'kpis' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizar(obj) {
      obj.idAcaoProjeto = obj.projeto && obj.projeto.id ? obj.projeto.id : null;
      obj.idCliente = obj.instituicao && obj.instituicao.id ? obj.instituicao.id : null;
      obj.idsAcao = obj.acoes.map((v) => v.id);
      this.resourceKpis.atualizar(obj)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.kpis = {};
          this.$router.push({ name: this.$route.params.from || 'kpis' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelar() {
      this.$router.push({ name: this.$route.params.from || 'kpis' });
    },
    abrirCadastro(metadados) {
      this.novaExtensao = {};
      this.novaExtensao.indAtivo = true;
      this.novaExtensao.idNivelExtensao = metadados.idNivelExtensao;
      this.novaExtensao.metadados = metadados;
      this.dialogCadastroExtensao = true;
    },
    carregarKpis() {
      const { id } = this.$route.params;
      skipLoading();
      this.resourceKpis.buscar({ id })
        .then((res) => {
          this.kpis = { ...this.kpis, ...res.body };
          this.statusEdicao = this.kpis.status;
          if (this.kpis.status !== 'EM_EXECUCAO') {
            this.somenteLeitura = true;
          }
          if (this.kpis.idAcaoProjeto) {
            this.carregarProjeto();
          }

          if (this.kpis.idCliente) {
            this.carregarCliente();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    carregarProjeto() {
      this.kpis.tipo = 'PROJETO';
      this.kpis.projeto = { id: this.kpis.idAcaoProjeto, nomProjeto: this.kpis.nomProjeto };
      this.buscarProjetos();
    },
    carregarCliente() {
      this.kpis.tipo = 'INSTITUICAO';
      this.kpis.instituicao = { id: this.kpis.idCliente, nomCliente: this.kpis.nomCliente };
      this.buscaClientes();
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.carregarKpis();
    }
  },
};
</script>

<style>
   .ProjetoForm__input .v-text-field__slot input {
      margin-top: 10px !important;
   }
</style>
