<template lang="html">
    <v-dialog v-model="dialogKpisAcoesRelacionadas" width="1400"  max-width="1400">
      <template v-slot:activator="{ on }">
        <v-row justify="end" class="mr-2 mb-2 mt-2">
          <v-btn id="btn_nova_acao" fab  x-small  color="primary"  @click="openModal()"   v-on="on" >
            <v-icon>add</v-icon>
          </v-btn>
        </v-row>
      </template>
      <v-card>
        <v-card-title class="pb-0">
          <span class="headline" >{{ $t('label.adicionar_acao') }}</span>
        </v-card-title>
        <v-container fluid >
          <v-col cols="9" sm="9" md=9 >
              <v-autocomplete
                id="usuario_notificacao"
                name="usuario_notificacao"
                v-model="acoesRelacionadas"
                :items="listaAcoes"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="nomComposto"
                item-value="id"
                :label="`${$tc('label.kpis_acao_etapa_planejamento', 1)}`"
                :search-input.sync="triggerAcoes"
                @click.native="buscarAcoes"
                clearable
                multiple
                return-object
                >
                <template
                  v-slot:selection="data">
                  <v-chip
                    :input-value="data.selected"
                    close
                    class="chip--select-multi"
                    @click:close="removeChipSelecao(data.item)">
                    {{ data.item.nomComposto }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar_acoes_kpis" @click.native="cancelar">{{ $t('label.cancelar') }}</v-btn>
            <v-btn id="btn_salvar_novo_kpis"  @click.native="addAcao" color="primary" :disabled="acoesRelacionadas.length < 1">{{ $t('label.adicionar') }}</v-btn>
          </v-card-actions>
         </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
import { getMoney } from '../../produto/common/functions/helpers';
import { buscarSelecaoAcoes } from '../../common/resources/akzo-kpis-sustentabilidade';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'KpisFormAcoesRelacionadasModal',
  components: {
    Confirm,
  },
  props: {
    kpis: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialogKpisAcoesRelacionadas: false,
      acoesRelacionadas: [],
      listaAcoes: [],
      acoes: {},
      triggerAcoes: null,
    };
  },
  watch: {
    triggerAcoes(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscarAcoes(val);
      }, 500);
    },
    acoesRelacionadas: {
      handler(newValue, oldValue) {
        const valorAdicionado = newValue.find((a) => !oldValue.includes(a));
        if (valorAdicionado && valorAdicionado.idKpis && this.kpis.id !== valorAdicionado.idKpis) {
          const msg = this.$tc('message.kpis_acao_vinculada');
          const msgAcaoAssociada = msg.replace('[cod_kpi]', valorAdicionado.idKpis);
          this.$toast(msgAcaoAssociada);
          this.removeChipSelecao(valorAdicionado);
        }
      },
      deep: true,
    },
  },
  computed: {
  },
  methods: {
    getMoney,
    closeNotificacacaoAcaoAssociada() {
      this.msgAcaoAssociada = null;
    },
    cancelar() {
      this.dialogKpisAcoesRelacionadas = false;
    },
    openModal() {
      this.acoesRelacionadas = this.kpis.acoes;
    },
    addAcao() {
      this.kpis.acoes = this.acoesRelacionadas;
      this.cancelar();
    },
    getNome(val) {
      return `${val.id} :: ${val.nomTipo} :: ${val.nomCliente} :: ${this.getMoney(val.vlrAcao)} :: ${this.getStatusAcao(val.status)}`;
    },
    buscarAcoes(autocomplete = null) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      const params = {
        autocomplete,
        idProjeto: this.kpis.projeto && this.kpis.projeto.id ? this.kpis.projeto.id : null,
        idCliente: this.kpis.instituicao && this.kpis.instituicao.id ? this.kpis.instituicao.id : null,
      };

      buscarSelecaoAcoes(params, this.$resource)
        .then((res) => {
          this.listaAcoes = res.data.map((val) => ({
            ...val,
            nomComposto: this.getNome(val),
          }));
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    removeChipSelecao(item) {
      const selecao = this.acoesRelacionadas;
      const index = selecao.map((s) => s.id).indexOf(item.id);
      if (index >= 0) selecao.splice(index, 1);
    },
    getStatusAcao(status) {
      let msg = '';
      switch (status) {
        case 'EM_CADASTRO':
          msg = this.$tc('label.em_cadastro');
          break;
        case 'AGUARDANDO_APROVACAO':
          msg = this.$tc('label.aguardando_aprovacao', 1);
          break;
        case 'APROVADO':
          msg = this.$tc('label.aprovado', 1);
          break;
        case 'EM_ANALISE':
          msg = this.$tc('label.em_analise', 1);
          break;
        case 'CANCELADO':
          msg = this.$tc('label.cancelado', 1);
          break;
        case 'REPROVADO':
          msg = this.$tc('label.reprovado', 1);
          break;
        default:
          return '';
      }
      return msg;
    },
  },
};
</script>
