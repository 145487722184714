<template>
  <div>
    <v-card-title>
      <div>
        <span>{{ $tc('label.anexo', 1)}}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0" style="margin-top: -4px !important;" :disabled="!podeAnexar" icon v-on="on" @click="() => $refs.uploadArquivo.open()">
              <v-icon>attach_file</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('title.upload_evidencia') }}</span>
        </v-tooltip>
      </div>
    </v-card-title>
    <p>
      <span  style="color: darkorange;" >
        {{$t('message.kpis_upload_disponivel')}}
      </span>
    </p>
    <div class="painel-anexo">
      <v-row >
        <list-files v-if="kpis && kpis.id"
          @ListFiles__arquivos="(files) => $emit('FormAnexoArquivo__qtde', files.length)"
          :habilitar-exclusao="podeRemover" ref="listFiles" :uri="uri"></list-files>
      </v-row>
    </div>
    <upload-file-form
      v-if="podeAnexar"
      ref="uploadArquivo"
      :title="$t('label.upload_anexo')"
      :url="uriUpload"
      :auto-process-queue="false"
      @UPLOADFILE_SENDING="enviandoArquivo"
      @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
      @UploadArquivo__success="uploadSucesso">
      <v-text-field
        v-model="descricaoArquivo"
        :rules="[rules.required]"
        :counter="150"
        maxlength="150"
        :label="`${$t('label.descricao')}*`"
        required
      ></v-text-field>
    </upload-file-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import basePath from '../../produto/common/functions/api-resource';
import ListFiles from '../../produto/shared-components/files/ListFiles';
import UploadFileForm from '../../produto/shared-components/upload/UploadFileForm';

export default {
  name: 'KpisFormAnexoArquivo',
  components: {
    UploadFileForm,
    ListFiles,
  },
  props: {
    kpis: {
      type: Object,
      required: true,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descricaoArquivo: null,
      exibeArquivos: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    uri() {
      const utlPayload = basePath('job', 'kpis-akzo/arquivo');
      return `${utlPayload}/${this.kpis.id}`;
    },
    uriUpload() {
      const utlPayload = basePath('job', 'kpis-akzo/arquivo');
      return `${utlPayload}/${this.kpis.id}/upload`;
    },
    hasRole() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'KPIS_SUSTENTA_CRUD').length;
    },
    podeAnexar() {
      return this.hasRole && this.kpis && this.kpis.id && this.kpis.status
      && (this.kpis.status !== 'CANCELADO' || (this.kpis.status === 'CANCELADO' && !this.somenteLeitura));
    },
    podeRemover() {
      return this.kpis && this.kpis.id && this.kpis.status && this.kpis.status === 'EM_EXECUCAO' && !this.somenteLeitura;
    },
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      formData.append('descricao', encodeURI(this.descricaoArquivo));
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    uploadSucesso() {
      setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
    },
  },
};
</script>
<style scoped>
.anexo-desabilitado {
  padding: 12px 0 0 12px;
  font-size: 16px !important;
  color: red;
}
.painel-anexo .container.mx-0 {
  max-width: 97% !important;
}
</style>
